.user-message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  
  .full-message-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .user-profile-img-container {
    margin-right: 10px;
  }
  
  .profile-img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 20px;
    margin-left: -10px;
  }
  
  .message-username-container {
    display: flex;
    flex-direction: column;
  }
  
  .message-username {
    margin-top: 16px;
    font-weight: 600;
    font-size: 20px;
    color:#a8aaac
  }
  
  .message-created-at {
    font-weight: 400;
    font-size: 10px;
    margin-left: 8px;
    color: #72767d;
  }
  
  .message-body {
    padding-top: 6px;
    font-size: 18px;
    color:#d4d4d4
  }
  
  img.profile-img {
    max-width: 50px;
    max-height: 50px;
  }

  .message-actions {
    display: flex;
    align-items: center;
  }
  
  .edit-message,
  .delete-message {
    background-color: transparent;
    color: #8e9297;
    border: none;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    transition: color 0.2s ease-in-out;
  }
  
  .edit-message:hover,
  .delete-message:hover {
    color: #dcddde;
  }
  
  .delete-message {
    color: #f04747;
  }
  
  .delete-message:hover {
    color: #f04747;
    text-decoration: underline;
  }
  
  .message-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .cancel-edit,
  .save-edit {
    background-color: #7289da;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    padding: 6px 12px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .cancel-edit:hover,
  .save-edit:hover {
    background-color: #677bc4;
  }
  
  .save-edit {
    background-color: #43b581;
  }
  
  .save-edit:hover {
    background-color: #3a9540;
  }
  
  .message-edit-body {
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: 1px solid #b9bbbe;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 10px;
  }

  .message-actions {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .user-message:hover .message-actions {
    opacity: 1;
  }