.server-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 300px;
    width: 300px;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
  }
  
  .server-card:hover {
    background-color: rgba(79, 84, 92, 0.16);
  }
  
  .server-card-cover {
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0 8px;
    overflow: hidden;
  }
  
  .server-card-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .server-card-cover-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    background-color: #7289da;
  }
  
  .server-card-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  
  .server-card-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 16px;
  }
  
  .server-card-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .server-card-icon-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    background-color: #7289da;
  }
  
  .server-card-details {
    flex: 1;
  }
  
  .server-card-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #7e7878;
  }  
  
  .server-card-members {
    font-size: 12px;
    line-height: 16px;
    color: #b9bbbe;
    margin-top: 4px;
  }
  
  .server-card-join {
    display: flex;
    align-items: center;
  }
  
  .server-card-join-button {
    padding: 8px 16px;
    margin: 20px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    background-color: #7289da;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .server-card-join-button:hover{
    background-color: #677bc4;
  }
  
  .server-card-joined {
    margin: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    background-color: #43b581;
    padding: 8px 16px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  