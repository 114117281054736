/* .home-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .home-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #36393f;
    color: #fff;
  }
  
  .home-nav-left {
    display: flex;
    align-items: center;
  }
  
  .home-logo-link {
    display: block;
  }
  
  .home-logo {
    width: 135px;
  }
  
  .home-nav-right {
    display: flex;
    align-items: center;
  }
  
  .home-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #202225;
    color: #fff;
  }
  
  .home-header {
    font-size: 64px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .home-subheader {
    font-size: 24px;
    margin-bottom: 40px;
  }
  
  .home-button {
    padding: 10px 20px;
    background-color: #7289da;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px
  }
  
  .home-button:hover {
    background-color: #677bc4;
  }
  
  .home-button:active {
    background-color: #566298;
  }
  
  .home-button:focus {
    outline: none;
  }
  
  .home-link {
    color: #fff;
    text-decoration: none;
  }
  
  .home-link:hover {
    text-decoration: underline;
  }
  
  .home-form {
    display: flex;
    flex-direction: column;
    background-color: #36393f;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }
  
  .home-label {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .home-input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
  }
  
  .home-input:focus {
    outline: none;
  }
  
  .home-error {
    color: #f04747;
    margin-bottom: 10px;
  }
  
  .home-checkbox-label {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .home-checkbox {
    margin-right: 10px;
  }
  
  .home-signup-link {
    color: #7289da;
    text-decoration: none;
  }
  
  .home-signup-link:hover {
    text-decoration: underline;
  }
  
  .home-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: #999;
  }
   */

   .home-container {
    background-color: #36393f;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 60px;
  }
  
  .home-nav {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2f3136;
    color: white;
    position: sticky;
    top: 0;
  }
  
  .home-nav-left {
    display: flex;
    align-items: center;
  }
  
  .home-logo {
    width: 50px;
    height: 50px;
  }
  
  .home-nav-right {
    display: flex;
    align-items: center;
  }
  
  .home-button {
    color: #7289da;
    background-color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .home-button:hover {
    background-color: #7289da;
    color: white;
  }
  
  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 60px;
  }
  
  .home-header {
    font-size: 60px;
    font-weight: bold;
    color: white;
    margin-bottom: 30px;
  }
  
  .home-subheader {
    font-size: 18px;
    color: white;
    max-width: 600px;
    margin-bottom: 60px;
  }
  
  .home-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #2f3136;
    color: white;
    font-size: 14px;
  }
  
  .home-footer a {
    color: #7289da;
    margin-left: 10px;
  }
  
  .home-footer i {
    font-size: 18px;
  }
  