.open-modal-button {
  border: none !important;
  outline: none;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  background-color: #484a4e;
  margin: 15px;
  margin-left: 30px;
  text-decoration: none;
  cursor: pointer;
}

.server-list {
  flex: 1;
  overflow-y: auto;
  padding: 0;
  margin: 0;
}

.server-list li {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.server-list li:hover {
  background-color: #40444b;
}

.server-list li a {
  text-decoration: none;
  color: #b9bbbe;
  font-size: 16px;
  margin-left: 12px;
}

.server-list li a.active {
  color: #fff;
  font-weight: bold;
}

.logout-button {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  background-color: #484a4e;
  color: rgb(216, 211, 211);
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
  margin-top: 15px;
  margin-left: 29px;
}


.all-servers-button-nav{
  border-radius: 100%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  background-color: #484a4e;
  color: rgb(37, 202, 111);
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
  margin-top: 25px;
  margin-left: 29px;
}