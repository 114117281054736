.message-body-container{
    border-radius: 8px;
    height: 40px;
    width: 100%;
    padding: 12px;
    color: white;
    background-color: #424549;
    border: 1px solid #2f3136;
}

.create-message-form{
    display: flex;
    width: auto;
    margin: 20px;
}

.create-message-btn{
    display: none;
}

.message-body-container {
    border: none;
    flex: 1;
    padding: 8px;
    font-size: 16px;
    color: #dcddde;
  }

  