.server-tab-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    margin: 0;
  }
  
  .nav-server-bar-main {
    width: 120px;
    height: 100%;
    background-color: #2f3136;
    border-right: 1px solid #40444b;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    
  }
  
  .channel-list-main {
    width: 15%;
    display: flex;
    flex-direction: column;
    background-color: #2f3136;
    height: 100%;
    overflow-y: auto;
  }
  
  .message-list-main {
    flex: 1;
    background-color: #36393f;
    border-radius: 0 0 8px 0;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #202225 #36393f;
  }
  
  .message-list-main::-webkit-scrollbar {
    width: 8px;
  }
  
  .message-list-main::-webkit-scrollbar-track {
    background-color: #36393f;
  }
  
  .message-list-main::-webkit-scrollbar-thumb {
    background-color: #202225;
    border-radius: 20px;
    border: 2px solid #36393f;
  }


  .nav-server-bar-main::-webkit-scrollbar {
    width: 8px;
  }
  
  .nav-server-bar-main::-webkit-scrollbar-track {
    background-color: #36393f;
  }
  
  .nav-server-bar-main::-webkit-scrollbar-thumb {
    background-color: #202225;
    border-radius: 20px;
    border: 2px solid #36393f;
  }
  