.server-icon{
    border-radius: 100%;
    height: 50px;
    width: 50px;
    object-fit: cover;
    margin: 10px;
}

.server-icon-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

a:link{
    text-decoration: none;
}

.default-icon{
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    background-color: #484a4e;
    margin: 10px;
    color: rgb(216, 211, 211);
    text-decoration: none;
}
