.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #36393f;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    background-color: #36393f;
    padding: 20px;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  
  .login-header {
    font-size: 24px;
    font-weight: bold;
    color: #dcddde;
    margin-bottom: 24px;
  }
  
  .login-errors {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #f04747;
    font-size: 14px;
    margin-bottom: 16px;
  }
  
  .login-email-label, .login-password-label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 15px;
    color: #dcddde;
  }
  
  .login-email-label input, .login-password-label input {
    border: 1px solid #2f3136;
    border-radius: 5px;
    padding: 12px 16px;
    font-size: 16px;
    color: #dcddde;
    background-color: #2f3136;
  }
  
  .login-email-label input::placeholder, .login-password-label input::placeholder {
    color: #8e9297;
  }
  
  .login-button {
    background-color: #7289da;
    border: none;
    border-radius: 5px;
    color: #dcddde;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 24px;
    padding: 12px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .login-button:hover {
    background-color: #677bc4;
  }
  
  .login-demo-login {
    font-size: 14px;
    color: #dcddde;
    margin-top: 24px;
  }
  
  .demo-login-button {
    background-color: #2f3136;
    border: none;
    border-radius: 5px;
    color: #dcddde;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    padding: 12px;
    transition: background-color 0.2s;
  }
  
  .demo-login-button:hover {
    background-color: #292b2f;
  }
  
  .login-register {
    font-size: 14px;
    color: #8e9297;
    margin-top: 24px;
  }
  
  .login-form-signup-link {
    background-color: #2f3136;
    border: none;
    border-radius: 5px;
    color: #dcddde;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
    padding: 8px 16px;
    transition: background-color 0.3s;
  }
  
  .login-form-signup-link:hover {
    background-color: #292b2f;
  }
  