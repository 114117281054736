.channel-card-main {
  color: #d3d3d3;
  padding: 12px 10px;
  border-bottom: 1px solid #292b2f;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.channel-card-main:hover {
  background-color: #292b2f;
  border-radius: 10px;
}

.channel-names {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.channel-card-name-container {
  display: flex;
  align-items: center;
}

.channel-card-name {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  max-height: 3em;
  display: -webkit-box;
}

.delete-channel-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.delete-channel-btn:hover {
  color: red;
}

.edit-channel-btn {
  margin-left: 10px;
}