.channel-list-container {
  display: flex;
  flex-direction: column;
  background-color: #2f3136;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
}

.channel-list-header {
  color: #dcddde;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-list-server-name {
  display: flex;
  color: white;
  padding-bottom: 20px;
  font-size: 20px;
}
.all-channels-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: auto
}

.dropdown-button {
  background-color: transparent;
  color: #d3d3d3;
  border: none;
  cursor: pointer;
}

.dropdown-button:hover {
  color: #fff;
}

.dropdown-menu {
  position: absolute;
  background-color: #292b2f;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  margin-top: 8px;
  min-width: 160px;
}

.dropdown-item {
  color: #d3d3d3;
  padding: 12px 16px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #3c3f44;
  color: #fff;
}


/* .dropdown {
  position: relative;
  display: inline-block;
  margin-left: auto;
}

.dropdown-button {
  background-color: #2f3136;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  appearance: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-button {
  background-color: #677bc4;
  border: none;
}


.dropdown-item{
  cursor: pointer;
}

.dropdown-menu{
  background-color: #677bc4;
} */
